/* App.css */
body {
  background-color: #02041D; /* Sets the entire page's background */
}


* {
  max-width: 100vw;
  overflow-x: hidden;
}




