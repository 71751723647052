@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap');

body {
    font-family: 'DM Sans', sans-serif;
  }

.container {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;
}

.section {
    flex: 1;
    margin: 10px; /* Adjust spacing between the two sections */
}